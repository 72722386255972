const actions = {

  // CART_ADD: 'CART_ADD',
  // CART_ADD_SUCCESS: 'CART_ADD_SUCCESS',
  // CART_ADD_FAILURE: 'CART_ADD_FAILURE',

  // CART_UPDATE: 'CART_UPDATE',
  // CART_UPDATE_SUCCESS: 'CART_UPDATE_SUCCESS',
  // CART_UPDATE_FAILURE: 'CART_UPDATE_FAILURE',
  
  // CART_RESET_ADDON_PRODUCT: 'CART_RESET_ADDON_PRODUCT',
  // CART_UPDATE_TOTALS: 'CART_UPDATE_TOTALS',
  // CART_INSERT_PRODUCT: 'CART_INSERT_PRODUCT',
  // CART_INSERT_PAD: 'CART_INSERT_PAD',
  // CART_UPDATE_PADS: 'CART_UPDATE_PADS',
  // CART_UPDATE_COUNTER: 'CART_UPDATE_COUNTER',
  // CART_UPDATE_PRODUCT_ADDONS: 'CART_UPDATE_PRODUCT_ADDONS',
  // CART_UPDATE_PRODUCT: 'CART_UPDATE_PRODUCT',
  // CART_UPDATE_PAD: 'CART_UPDATE_PAD',
  // CART_UPDATE: 'CART_UPDATE',
  // CART_UPDATE_HIRE_SAVING: 'CART_UPDATE_HIRE_SAVING',
  // CART_RESET: 'CART_RESET',
  // CART_INC_QTY: 'CART_INC_QTY',
  // CART_DEC_QTY: 'CART_DEC_QTY',
  // CART_QTY_CHANGE: 'CART_QTY_CHANGE',
  // CART_TOTAL_WEIGHT: 'CART_TOTAL_WEIGHT',
  // CART_UPDATE_PRODUCT_STOCK_CHECK: 'CART_UPDATE_PRODUCT_STOCK_CHECK',
  // CART_UPDATE_EXTRAS_STOCK_CHECK: 'CART_UPDATE_EXTRAS_STOCK_CHECK',
  // CART_SET_CHANGED: 'CART_SET_CHANGED',
  // CART_SET_CHANGED_SUCCESS: 'CART_SET_CHANGED_SUCCESS',
  // CART_SET_CHANGED_FAILURE: 'CART_SET_CHANGED_FAILURE',

  // CART_SET_CART: 'CART_SET_CART',
  // CART_SET_CART_SUCCESS: 'CART_SET_CART_SUCCESS',
  // CART_SET_CART_FAILURE: 'CART_SET_CART_FAILURE',

  // CART_REMOVE: 'CART_REMOVE',
  // CART_REMOVE_SUCCESS: 'CART_REMOVE_SUCCESS',
  // CART_REMOVE_FAILURE: 'CART_REMOVE_FAILURE',

  // UPDATE_CART: 'UPDATE_CART',
  // UPDATE_CART_SUCCESS: 'UPDATE_CART_SUCCESS',
  // UPDATE_CART_FAILURE: 'UPDATE_CART_FAILURE',

  // CART_SET_ALTERNATIVES: 'CART_SET_ALTERNATIVES',
  // CART_SET_ALTERNATIVES_SUCCESS: 'CART_SET_ALTERNATIVES_SUCCESS',
  // CART_SET_ALTERNATIVES_FAILURE: 'CART_SET_ALTERNATIVES_FAILURE',

  // CART_SET_EXTRAS_ALTERNATIVES: 'CART_SET_EXTRAS_ALTERNATIVES',
  // CART_SET_EXTRAS_ALTERNATIVES_SUCCESS: 'CART_SET_EXTRAS_ALTERNATIVES_SUCCESS',
  // CART_SET_EXTRAS_ALTERNATIVES_FAILURE: 'CART_SET_EXTRAS_ALTERNATIVES_FAILURE',

  // CART_UPDATE_PRODUCT_FROM_ALTERNATIVE: 'CART_UPDATE_PRODUCT_FROM_ALTERNATIVE',
  // CART_UPDATE_PRODUCT_FROM_ALTERNATIVE_SUCCESS: 'CART_UPDATE_PRODUCT_FROM_ALTERNATIVE_SUCCESS',
  // CART_UPDATE_PRODUCT_FROM_ALTERNATIVE_FAILURE: 'CART_UPDATE_PRODUCT_FROM_ALTERNATIVE_FAILURE',

  // CART_UPDATE_PRODUCT_EXTRAS_FROM_ALTERNATIVE: 'CART_UPDATE_PRODUCT_EXTRAS_FROM_ALTERNATIVE',
  // CART_UPDATE_PRODUCT_EXTRAS_FROM_ALTERNATIVE_SUCCESS: 'CART_UPDATE_PRODUCT_EXTRAS_FROM_ALTERNATIVE_SUCCESS',
  // CART_UPDATE_PRODUCT_EXTRAS_FROM_ALTERNATIVE_FAILURE: 'CART_UPDATE_PRODUCT_EXTRAS_FROM_ALTERNATIVE_FAILURE',

  // CART_UPDATE_CART_FROM_ALTERNATIVE: 'CART_UPDATE_CART_FROM_ALTERNATIVE',
  // CART_UPDATE_CART_FROM_ALTERNATIVE_SUCCESS: 'CART_UPDATE_CART_FROM_ALTERNATIVE_SUCCESS',
  // CART_UPDATE_CART_FROM_ALTERNATIVE_FAILURE: 'CART_UPDATE_CART_FROM_ALTERNATIVE_FAILURE',

  // CART_UPDATE_CART_EXTRAS_FROM_ALTERNATIVE: 'CART_UPDATE_CART_EXTRAS_FROM_ALTERNATIVE',
  // CART_UPDATE_CART_EXTRAS_FROM_ALTERNATIVE_SUCCESS: 'CART_UPDATE_CART_EXTRAS_FROM_ALTERNATIVE_SUCCESS',
  // CART_UPDATE_CART_EXTRAS_FROM_ALTERNATIVE_FAILURE: 'CART_UPDATE_CART_EXTRAS_FROM_ALTERNATIVE_FAILURE',

  // CART_SET_UPDATED_ALTERNATIVE_STATE: 'CART_SET_UPDATED_ALTERNATIVE_STATE',
  // CART_SET_UPDATED_ALTERNATIVE_STATE_SUCCESS: 'CART_SET_UPDATED_ALTERNATIVE_STATE_SUCCESS',
  // CART_SET_UPDATED_ALTERNATIVE_STATE_FAILURE: 'CART_SET_UPDATED_ALTERNATIVE_STATE_FAILURE',

  // CART_SET_ADDED_MODAL: 'CART_SET_ADDED_MODAL',
  // CART_SET_ADDED_MODAL_SUCCESS: 'CART_SET_ADDED_MODAL_SUCCESS',
  // CART_SET_ADDED_MODAL_FAILURE: 'CART_SET_ADDED_MODAL_FAILURE',

  // CART_SET_ADDON_PRODUCT: 'CART_SET_ADDON_PRODUCT',
  // CART_SET_ADDON_PRODUCT_SUCCESS: 'CART_SET_ADDON_PRODUCT_SUCCESS',
  // CART_SET_ADDON_PRODUCT_FAILURE: 'CART_SET_ADDON_PRODUCT_FAILURE',

  

  
}

export default actions;
