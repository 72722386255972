import { useDispatch } from "react-redux";
import cartActions from "@components/Redux/CartManagement/actions";
import userActions from "@components/Redux/User/actions";
import modalActions from "@components/Redux/Modals/actions";
import useCartHandler from "./useCartHandler";
import { useEffect, useState } from "react";

const usePadActions = () => {
    const dispatch = useDispatch();

    const [select, setPadSelect] = useState("");
    const [padSelector, setShowPadSelector] = useState(false);
    const [padProps, setPadProps] = useState(null);
    const [padQty, setPadQty] = useState(1);

    const {addToCart} = useCartHandler();
    

    //pad selector
    useEffect(() => {
      if (padProps != false && padProps != null) {
        // productID, slug, qty = 1, bow, pad, color, source, section_id = 4, padProduct = null
        addToCart(
          padProps.id,
          padProps.product_slug,
          padQty, //qty
          padProps.bow,
          padProps.pad,
            null, //color
          padProps.source,
          padProps.sectionID,
          padProps.padProduct,
        );
        setPadProps(false);
        setShowPadSelector(false);
      }
    }, [padProps]);

    const handleExtra = (product, qty) => {
        if(product.pad_color_option == 'yes'){
            setShowPadSelector({
                product_id: product.product_id,
                open: true,
            });

            setPadQty(qty);
        }
    }

    const cancelPadSelection = () => setShowPadSelector(false);

    const handlePadSelector = (
      master,
      product_id,
      product_slug,
      bow,
      pad,
      source,
      sectionID,
      type,
      doris_product_code,
      name,
    ) => {
      let slug;
      let data;
      let pad_id;
  
      if(pad == 'yes') {
        master.product.pad_options.map((pad) => {
          if (pad.product_code == doris_product_code) {
            data = pad;
            slug = pad.product_slug;
            pad_id = pad.product_id;
          }
        });
      }
  
      setPadProps({
        source: source,
        sectionID: sectionID,
        type: type,
        bow: bow,
        pad: pad,
        product_slug: product_slug,
        id: product_id,
        padProduct: {
          id: pad_id,
          name: name,
          data: data,
          product_slug: slug,
        },
      });
      // setPadProps({
      //     product_id: product_id, name: name, product_slug: slug, data: data
      // })
    };

    const handlePadSelect = (e) => {
      const value = e.target.value;
      setPadSelect(value);
    };



    return {
        handlePadSelect,
        padSelector,
        setShowPadSelector,
        handlePadSelector,
        select,
        handleExtra,
        cancelPadSelection
    }
}

export default usePadActions;