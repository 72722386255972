import { useDispatch } from "react-redux";
import cartActions from "@components/Redux/CartManagement/actions";
import userActions from "@components/Redux/User/actions";
import modalActions from "@components/Redux/Modals/actions";

const useCartDispatch = () => {
    const dispatch = useDispatch();

    const RESET_CART = () => {
        dispatch({type: cartActions.BUCKET_RESET_CART})
    }

    const UPDATE_CART = (newCart) => {
        dispatch({
            type: cartActions.BUCKET_UPDATE_CART,
            payload: newCart
       })
    }

    const RESET_ADDON_PRODUCT = () => dispatch({type: cartActions.RESET_ADDON_PRODUCT})

    const UPDATE_CART_COUNTER = (counter) => {
         dispatch({
             type: cartActions.BUCKET_UPDATE_CART_COUNTER,
             payload: counter
        })
    }

    const SET_TOKEN = (token) => {
        dispatch({
            type: userActions.SET_TOKEN,
            payload: token
       })
    }

    const SET_TOP_CART_MODAL = (open = false, type = null, title = null, other = {}) => {
        const data = {
            open,
            type,
            title,
            ...other
        };
        dispatch({
            type: modalActions.HANDLE_TOP_CART_MODAL_TOGGLE,
            payload: data
       })
    }

    const SET_CART_LOADER = (loader) => {
        dispatch({
            type: cartActions.BUCKET_SET_CART_LOADER,
            payload: loader
        })
    }

    const RETRIEVE_QUOTE_LOADER = (loader) => {
        dispatch({
            type: cartActions.SET_CART_RETRIEVE_QUOTE_LOADER,
            payload: loader
        })
    }

    const SET_PRICES_LOADER = (loader) => {
        dispatch({
            type: cartActions.BUCKET_SET_UPDATING_PRICES_LOADER,
            payload: loader
        })
    }
    
    const UPDATE_CART_KEY = (key, data) => {
        dispatch({
            type: cartActions.CART_UPDATE_KEY,
            payload: {
                key: key,
                data: data
            }
        })
    }

    const SET_CART_ADDON_PRODUCT = (key, product) => {
        dispatch({
            type: cartActions.SET_CART_ADDON_PRODUCT,
            payload: {
                key: key,
                data: product
            }
        })
    }

    const UPDATE_CART_INDEX = (idx, padIdx, data) => {
        console.log(data)
        dispatch({
            type: cartActions.SET_CART_INDEX_DATA,
            payload: {
                idx: idx,
                padIdx: padIdx,
                data: data
            }
        })
    }

    return {
        RESET_CART,
        UPDATE_CART,
        UPDATE_CART_COUNTER,
        SET_TOKEN,
        SET_TOP_CART_MODAL,
        SET_CART_LOADER,
        SET_PRICES_LOADER,
        UPDATE_CART_KEY,
        SET_CART_ADDON_PRODUCT,
        RETRIEVE_QUOTE_LOADER,
        UPDATE_CART_INDEX,
        RESET_ADDON_PRODUCT
    }
}

export default useCartDispatch;