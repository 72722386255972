import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const MAX_QTY = process?.env?.NEXT_PUBLIC_MAX_QTY || 99999

const useCartActions = () => {
    const dispatch = useDispatch();

    const { cart, addonProduct } = useSelector((state) => state.cartManagementReducer);
    const [qty, setQty] = useState(false);


    const handleQtyChange = (event, product_id, type = null, changeDOMElement = false) => {
        let productQty = parseInt(event.target.value);

        if(productQty > MAX_QTY){
          productQty = MAX_QTY;
          event.target.value = MAX_QTY
        }

        const changedByButton = type == 'add' || type == 'subtract';
        const el = document.querySelector(`#product-${product_id}`)
        if (isNaN(productQty) && !changedByButton) return;

        if(type == 'add' || type == 'subtract'){
            if(!qty[product_id]) {
              productQty = 1;
            }
    
            else {
              productQty = qty[product_id]
            }

            if(type == 'add') {
              productQty = productQty + 1;
            }
            else if(type == 'subtract'){
              productQty = productQty - 1
              if(productQty <= 0) productQty = 1;
            }
        }
        if(productQty <= 0) productQty = 1;

        if(changeDOMElement){
          if(productQty.toString().length > 5){
            el.value = MAX_QTY
          }
          else {
            el.value = productQty;
          }
        }

        setQty((prevState) => ({
            ...prevState,
            [product_id]: productQty,
        }));
    };

    return {
        handleQtyChange,
        qty,
        setQty
    }
}

export default useCartActions;