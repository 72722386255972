import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Clipboard from '@mui/icons-material/ContentCopy';
import Box from "@mui/material/Box"
import Tooltip from "@mui/material/Tooltip"
import actions from "@components/Redux/Global/actions";


const useGeneralActions = () => {
    const dispatch = useDispatch();

    const setPageSpinner = (value) => {
        dispatch({
            type: actions.SET_PAGE_SPINNER,
            payload: value
        })
    }

    const copyToClipboard = (val) => {
        try {
            navigator.clipboard.writeText(val)
            
        } catch (error) {
            console.log(error)
        }
    }

    const Copy = ({children, value, boxProps = {}, ...rest}) => (
        <Box {...boxProps}>
            {children}
            <Tooltip 
                arrow
                title={value}
                onClick={() => copyToClipboard(value)}
            >
                <Clipboard className="pl-1 pointer" sx={{color: (theme) => theme.palette.grey[800]}}/>
            </Tooltip>
        </Box>

    )

    return {
        setPageSpinner,
        copyToClipboard, 
        Copy
    }
}

export default useGeneralActions;